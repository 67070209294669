import React from 'react'
import {FaLinkedin, FaInstagram, FaYoutube} from 'react-icons/fa'

const Footer = () => {
	return (
		<div className='lg:hidden fixed bottom-0 w-full bg-[#0a192f]'>
			<div className='max-w-[350px] mx-auto p-2 h-full'>
					<ul className='grid grid-cols-3 justify-items-center'>
						<li className='w-[60px] h-[60px] flex justify-between items-center duration-300 bg-blue-600 rounded-lg'>
							<a className='flex justify-between items-center w-full text-gray-300' rel='noreferrer' target="_blank" href='https://www.linkedin.com/in/vadym-valihun/'>
								<FaLinkedin size={30} />
							</a>
						</li>
						<li className='w-[60px] h-[60px] flex justify-between items-center duration-300 bg-[#c4302b] rounded-lg'>
							<a className='flex justify-between items-center w-full text-gray-300' rel='noreferrer' target="_blank" href='https://www.youtube.com/@vadymshouse5440'>
								<FaYoutube size={30} />
							</a>
						</li>
						<li className='w-[60px] h-[60px] flex justify-between items-center bg-gradient-to-r from-[#ee2a7b] to-[#6228d7] rounded-lg'>
							<a className='flex justify-between items-center w-full text-gray-300' rel='noreferrer' target="_blank" href='https://www.instagram.com/w.vadym/'>
								<FaInstagram size={30} />
							</a>
						</li>
					</ul>
				</div>

			</div>
	)
}

export default Footer
import React from 'react'

const Contact = () => {
	return (
		<div name='contact-form' className='w-full h-screen bg-[#0a192f] flex justify-center items-center p-4'>
			<form method='POST' name='contact' action='/contact' className='flex flex-col max-w-[600px] w-full' netlify>
				<input type="hidden" name='form-name' value="contact" />
				<div className='pb-8'>
					<p className='text-4xl font-bold inline border-b-4 border-yellow-300 text-gray-300'>Contact</p>
					<p className='text-gray-300 py-6'>// Submit the form below or shoot me an email - vadymvaligun@gmail.com</p>
				</div>
				<input required className='bg-[#ccd6f6] p-2' type='text' placeholder='Name' name='name' />
				<input required className='my-4 p-2 bg-[#ccd6f6]' type='text' placeholder='Email' name='email' />
				<textarea required className='bg-[#ccd6f6] p-2' name='message' rows='10' placeholder='Message'></textarea>
				<button type='submit' className='text-white border-2 hover:bg-yellow-300 hover:border-yellow-300 px-4 py-3 my-8 mx-auto flex items-center'>Let's Collaborate</button>
			</form>
		</div>
	)
}

export default Contact
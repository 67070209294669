import React from 'react'
import Colorgrade from '../assets/projects/Colorgrading.png'
import MusicVideo from '../assets/projects/music-video.png'
import ShortMovieLife from '../assets/projects/sm-life.png'
import Rain from '../assets/projects/mv-rain.jpg'

const Work = () => {
	return (
		<div name='work' className='w-full md:h-screen text-gray-300 bg-[#0a192f]'>
			{/* Container */}
			<div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
				<div className='pb-8 pt-16'>
					<p className='text-4xl font-bold inline border-b-4 text-gray-300 border-yellow-300'>Work</p>
					<p className='py-6'>// Check out some of my recent work</p>
				</div>

				<div className='grid sm:gird-cols-2 md:grid-cols-3 gap-4'>
					<div style={{backgroundImage: `url(${MusicVideo})`}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>
						{/* HOVER EFFECTS */}
						<div className='opacity-0 group-hover:opacity-100 text-center'>
							<span className='text-2xl font-bold text-white'>Music Video - "Gasoline"</span>
							<div className='pt-8 text-center'>
								<a href='https://www.youtube.com/watch?v=mSGUOg1siz0'>
									<button className='text-center rounder-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
								</a>
							</div>
						</div>
					</div>
					<div style={{backgroundImage: `url(${Colorgrade})`}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>
						{/* HOVER EFFECTS */}
						<div className='opacity-0 group-hover:opacity-100 text-center'>
							<span className='text-2xl font-bold text-white'>Colorgrade - "Euphoria"</span>
							<div className='pt-8 text-center'>
								<a href='https://www.youtube.com/watch?v=rLR4cUhgBlU'>
									<button className='text-center rounder-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
								</a>
							</div>
						</div>
					</div>
					<div style={{backgroundImage: `url(${ShortMovieLife})`}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>
						{/* HOVER EFFECTS */}
						<div className='opacity-0 group-hover:opacity-100 text-center'>
							<span className='text-2xl font-bold text-white'>Short Movie - "Life"</span>
							<div className='pt-8 text-center'>
								<a href='https://www.youtube.com/watch?v=xEMBlMVUjqg'>
									<button className='text-center rounder-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
								</a>
							</div>
						</div>
					</div>
					<div style={{backgroundImage: `url(${Rain})`}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>
						{/* HOVER EFFECTS */}
						<div className='opacity-0 group-hover:opacity-100 text-center'>
							<span className='text-2xl font-bold text-white'>Music Video "Rain"</span>
							<div className='pt-8 text-center'>
								<a href='https://www.youtube.com/watch?v=ZVJ3VkTP-Mo'>
									<button className='text-center rounder-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Work